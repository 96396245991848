import styled from "@emotion/styled";
import IconButton from '@mui/material/IconButton';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {useState} from "react";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import {useContextProvider} from "../../providers/contextProvider";

const Box = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`
const UploadBox = styled("div")`
    //background: #fff;
  border-radius: 20px;
    //margin-top: 30px;
    display: flex;
  align-items: center;
  justify-content: center;
  //height: 50px;
  //box-shadow: 0px 1px 2px rgba(8, 15, 24, 0.1);
  //flex-grow: 1;
  margin-left: 30px;
`

const FileBox = styled("div")`
    display: flex;
  align-items: center;
  cursor: pointer;
  color: #343EDF;
  span{
    padding-right: 20px;
 
  }
`


export default function Step1(){
    const [file,setFile] = useState();
    const {state,dispatch} = useContextProvider(null);
    const [fileName,setFileName] = useState('');

    const updatePdf  = (e) =>{
        const { files } = e.target ;
        const { name } = files[0]
        setFileName(name);
        setFile(files[0]);
        dispatch({type: 'SET_FILE', payload: files[0]});
    }
    const RemovePdf = () =>{
        setFile(null);
        setFileName('')
    }

    return <Box>
        <div className="stepLine">
            <div className="stepNo">1</div>
            <div className="stepTitle">Upload Contract</div>
        </div>
        <UploadBox>
            {
                !fileName &&<IconButton color="primary" component="label">
                    <input hidden accept="application/pdf" type="file"  onChange={(e)=>updatePdf(e)} />
                    <FileUploadIcon fontSize="small" color="primary" />
                </IconButton>
            }
            {
                !!fileName &&<FileBox >
                    <span> {fileName}</span><DeleteOutlinedIcon onClick={()=>RemovePdf()}/>
                </FileBox>
            }

        </UploadBox>
    
    </Box>
}