import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import {useContextProvider} from "../../providers/contextProvider";
import {SignAPI,PdfSignViewer} from "test-dao-sign";
import {useWeb3React} from "@web3-react/core";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const Box = styled("div")`
    padding-bottom: 60px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const Tips = styled("div")`
    padding: 12px 0 0 20px;
  font-size: 12px;
  opacity: 0.6;
`

const PdfBox = styled("div")`
    flex-grow: 1;
  margin-bottom: 40px;
`

const BtnGroup = styled("div")`
    display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 10px;
  width: 100%;
  z-index: 9999;
  box-sizing: border-box;
  background: rgba(255,255,255,0.7);
`
const Line = styled("div")`
  display: flex;
  align-content: center;
`


export default function Step3(){
    const { account } = useWeb3React();
    const {state,dispatch} = useContextProvider(null);
    const {addList,fileHash,file} = state;
    const navigate = useNavigate();
    const [list,setList] = useState([]);


    const handleSubmit = (index) =>{
        dispatch({type: 'SET_CURRENT', payload: index});
    }

    const handleAdd = (list) =>{
        setList(list);
    }

    const handleCreate = async() =>{
        dispatch({type:"SET_LOADING",payload:true})
        let strArr=[];
        list.map((item)=>{
            strArr.push(JSON.stringify(item))
        })
        let addressArr = [];
        addList.map((item)=>{
            addressArr.push(item.address)
        })

        let expire = new Date().valueOf() + 7 * 24 *3600;

        let rt = await SignAPI.CreateSign(account,file.name,expire,fileHash,addressArr,strArr)
        dispatch({type:"SET_LOADING",payload:null});
        dispatch({type: 'SET_CURRENT', payload: 1});
        dispatch({type: 'SET_ADD_LIST', payload:null});
        dispatch({type: 'SET_FILE_HASH', payload: null});
        dispatch({type: 'SET_FILE', payload: null});
        if(rt?.type === "success"){
            navigate("/");
        }

    }

    return <Box>
        <Line>
            <div className="stepLine">
                <div className="stepNo">3</div>
                <div className="stepTitle">Prepare Documents</div>
            </div>
            <Tips>Select the signer and choose the signature position on the document</Tips>
        </Line>

        <PdfBox>
            <PdfSignViewer handleADD={handleAdd} signToDoList={addList} hashUrl={fileHash}  currentSigner={account} type="ADD" />
        </PdfBox>
        <BtnGroup>
            <Button variant="outlined" onClick={()=>handleSubmit(1)}>Back</Button>
            <Button variant="contained" onClick={()=>handleCreate()} disabled={!list.length}>Create</Button>
        </BtnGroup>
    </Box>
}