import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import Home from "../home";
import Create from "../create";
import Sign from "../sign";
import View from "../view";

export default function routerlink() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} index />
          <Route path="/home" element={<Home />} />
          <Route path="/create" element={<Create />} />
          <Route path="/sign/:id" element={<Sign />} />
          <Route path="/view/:id" element={<View />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}
