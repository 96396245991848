import Header from "./components/header";
import NoItem from "./components/home/noItem";
import styled from "@emotion/styled";
import {useState} from "react";
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import {useNavigate} from "react-router-dom";
import List from "./components/home/list";


const BoxBg = styled("div")`
    display: flex;
  flex-direction: column;
  min-height: 100vh;
`
const ContentBox = styled("div")`
padding: 20px 0;
  background: #f9f9fc;
  flex-grow: 1;
`
const RhtBox = styled('div')`
  display: flex;
  justify-content: flex-end;
align-items: center;
  white-space: nowrap;
`
const Item = styled('div')`
  width: 100%;
`
const ListBox = styled('div')`
    padding: 10px 0;
`

export default function Home(){
    // const [list,setList] = useState([]);
    const navigate = useNavigate();

    const handleToGo = (url) =>{
        navigate(url);
    }

    return <BoxBg>
        <Header />
        <ContentBox>
            <div className="mainContent">
                <Grid container spacing={2}>
                    <Grid xs={6} md={8} item>
                        <Item>
                            {/*<Input*/}
                            {/*    id="input-with-icon-adornment"*/}
                            {/*    startAdornment={*/}
                            {/*        <InputAdornment position="start">*/}
                            {/*            <SearchIcon />*/}
                            {/*        </InputAdornment>*/}
                            {/*    }*/}
                            {/*/>*/}
                        </Item>
                    </Grid>
                    <Grid xs={6} md={4} item>
                        <RhtBox>
                            <Button variant="outlined" onClick={()=>handleToGo("/create")}>+ New Doc</Button>
                        </RhtBox>
                    </Grid>
                </Grid>
                <ListBox>
                    <List />
                </ListBox>
            </div>


        </ContentBox>
        {/*{*/}
        {/*    !list.length && <NoItem tips="You haven't created and signed any agreements yet." />*/}
        {/*}*/}

    </BoxBg>
}