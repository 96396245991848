
const format = (dateTime) => {
    if(!dateTime)return '';
    const time = new Date(Number(dateTime) * 1000);
    const y = time.getFullYear();
    const m = time.getMonth() + 1;
    const d = time.getDate();
    const h = time.getHours();
    const mm = time.getMinutes();
    const s = time.getSeconds();
    return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d) + ' ' + (h < 10 ? '0' + h : h) + ':' + (mm < 10 ? '0' + mm : mm) + ':' + (s < 10 ? '0' + s : s);
}

const AddresstoShow = (address) => {
    if (!address) return "...";

    let frontStr = address.substring(0, 6);

    let afterStr = address.substring(address.length - 4, address.length);

    return `${frontStr}...${afterStr}`;
};

export default {
    format,
    AddresstoShow
};
