import styled from "@emotion/styled";
import Header from "./components/header";
import ShareIcon from '@mui/icons-material/Share';
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {SignAPI,PdfSignViewer} from "test-dao-sign";
import {useWeb3React} from "@web3-react/core";

const BoxBg = styled("div")`
    display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const ContentBox = styled("div")`
  padding: 10px 80px 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: #f9f9fc;
`

const PdfBox = styled("div")`
    flex-grow: 1;
`
const TitleBox = styled("div")`
    display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
  .title{
    padding-right: 20px;
  }
`

export default function View(){
    const { account } = useWeb3React();
    const {id} = useParams();
    const [detail,setDetail] = useState();


    useEffect(()=>{
        if(!id || !account)return;
        getDetail(id)
    },[id,account])

    const getDetail = async(id) =>{
        let details = await SignAPI.getAgreementDetail(id);
        setDetail(details);
    }

    return <BoxBg>
        <Header />
        <ContentBox>
            {/*<TitleBox>*/}
            {/*    <div className="title">{detail?.name}</div>*/}
            {/*    <ShareIcon fontSize="small" color="primary"/>*/}
            {/*</TitleBox>*/}
            <PdfBox>
                <PdfSignViewer signToDoList={detail} currentSigner={account} type="VIEW" />
            </PdfBox>
        </ContentBox>

    </BoxBg>
}