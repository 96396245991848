import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import * as React from "react";
import Dialog from '@mui/material/Dialog';
import {styled} from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { Wallet as WalletItems,getConnectorForWallet } from "../../providers/connector";
import {useState} from "react";

const MainUl = styled('ul')`
  margin: 0 29px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
    li{
      width: 48%;
      border-radius: 5px;
      background: #fff;
      box-shadow: 0px 1px 2px rgba(8, 15, 24, 0.1);
      box-sizing: border-box;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      color: #000;
      cursor: pointer;
      span{
        padding-left: 20px;
      }
      &.active{
        background: #F3EFFF;
      }
    }
`

const MaskBox = styled(Dialog)`
    .MuiDialog-paper{
      background: #f9f9fc!important;
    }
  .MuiButton-outlined{
    background: #fff!important;
  }
  .actions{
    padding:20px 30px;
    display: flex;
    align-content: center;
    justify-content: space-between;
  }
`

export default function ConnectBtn(props){
    const {open,handleClose} = props;
    const [list] = useState([
        {
            name: "Meta Mask",
            type: WalletItems.METAMASK,
        },
        {
            name: "Wallet Connect",
            type: WalletItems.WALLET_CONNECT,
        },
        {
            name: "Coinbase",
            type: WalletItems.COINBASE_WALLET,
        },
    ])
    const [type,setType] = useState(WalletItems.METAMASK);
    const [current,setCurrent] = useState(0);

    const handleType = (index) => {
        setType(list[index].type)
        setCurrent(index)
    }


    const connectWallet =  async () =>{
        try {
            const connector = getConnectorForWallet(type);
            await connector.activate();
            // if (connector.connectEagerly) {
            //     await connector.connectEagerly();
            // } else {
            //     await connector.activate();
            // }

            localStorage.setItem("wallet", type);
            // dispatch({ type: "CHANGE_WALLET", payload: selectWalletItem });
        } catch (error) {
            console.error(`web3 connection error: ${error}`);
            // setLoginLoading(false);
        }
        handleClose()
    }

    return <div>
        <MaskBox open={open}>
            <DialogTitle>Please select sign-in method</DialogTitle>
            <MainUl>
                {
                    list.map((item,index)=>(<li onClick={()=>handleType(index) } key={index} className={current === index ?"active":""}><AccountBalanceWalletOutlinedIcon /> <span>{item.name}</span></li>))
                }

            </MainUl>
            <div className="actions">
                <Button variant="outlined" onClick={handleClose}>Close</Button>
                <Button variant="contained" onClick={connectWallet}>Confirm</Button>
            </div>
        </MaskBox>
    </div>
}