import {styled} from "@mui/material/styles";

const Box = styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`

export default function NoItem(props){
    const {tips} = props;
    return <Box>{tips}</Box>
}