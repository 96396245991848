import { createGlobalStyle } from "styled-components";
import "../scss/fonts.scss";

const GlobalStyle = createGlobalStyle`
  :root {
    font-family: "Poppins-Regular",Roboto, Helvetica, Arial, sans-serif;
  }
  body, html{
    margin: 0;
    min-height: 100%;
  }
  * {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0 ;
      display: none;
    }
  }
  ul, li, dl, dt, dd{
    padding: 0;
    margin: 0;
    list-style: none;
  }
  a {
    text-decoration: none;
    color: unset;
    &:hover {
      color: unset;
    }
  }
  input, textarea {
    caret-color: #328aff;
    border: none;
    font-size: 14px;
    &:focus-visible {
      outline: none;
    }
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    margin: 0; 
  }
  input[type=number] {
      -moz-appearance:textfield;
  }
  .stepLine{
    display: flex;
    align-items: center;
    .stepNo{
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #343EDF;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0px 1px 2px rgba(8, 15, 24, 0.1);
      //border: 1px solid #cecece;
    }
    .stepTitle{
      padding-left: 20px;
      font-size: 16px;
    }
  }
  .mainContent{
    //width: 800px;
    margin: 0 80px;
  }
`;

export default GlobalStyle;
