import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import Pagination from '@mui/material/Pagination';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import {SignAPI} from "test-dao-sign";
import {useEffect, useState} from "react";
import PublicJs from "../../utils/public";
import {useWeb3React} from "@web3-react/core";

const RhtLine = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
`

const Box = styled("div")`
  button{
    width: 80px;
  }
  thead{
    th,td{
      border-bottom: 0;
      padding-left: 40px;
      padding-right: 40px;
      font-family: "Poppins-Bold";
      &:last-child{
        padding-right: 80px;
      }
    }
  }
  tbody{
    td,th{
      height: 30px;
      padding: 10px 40px;
      border-bottom: 0;
    }
    tr:nth-of-type(2n+1){
      td,th{
        background: #fff;

      }
      td:first-of-type{
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      td:last-child{
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        
      }
    }
    
  }
 
`


export default function List() {

    const [list,setList] = useState([]);
    const { account } = useWeb3React();

    const navigate = useNavigate();

    useEffect(
        ()=>{
            if(!account)return;
            getList()
        },[account])

    const getList = async() =>{
        let listArr = await SignAPI.getAgreementList();

        if(!listArr.length) return;
        let arr = [];
        listArr.map((item,index)=>{
            const signersArr = item.signers.filter((innerItem) => innerItem === account)
            if(signersArr.length){
                arr.push(item)
            }
        })
        setList(arr)
    }

    const handleToGo = (id,status) =>{
        if(status === 1){
            navigate(`/sign/${id}`);
        }else{
            navigate(`/view/${id}`);
        }

    }

    const switchStatus = (type) =>{
        let str;
        switch (type){
            case 1:
                str = "Pending Signature";
                break;
            case 2:
                str = "Expired";
                break;
            case 3:
                str = "Completed";
                break;
            default:
                str = "";
                break;
        }
        return str;

    }
    const handleDownload = async (id) =>{
       await SignAPI.handleDownload(id);
    }
    return (
        <Box>
            <TableContainer >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">State</TableCell>
                            <TableCell align="right">Created At</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((row,index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{row.name}</TableCell>
                                <TableCell align="right">{switchStatus(row.status)}</TableCell>
                                <TableCell align="right">{PublicJs.format(row.createAt)}</TableCell>
                                <TableCell align="right">
                                    {
                                       ( row.status === 3 || row.status === 2  ) &&<Button variant="text"  onClick={()=>handleToGo(row.id,row.status)}>View</Button>
                                    }
                                    {
                                        row.status === 3 &&<Button variant="text" onClick={()=>handleDownload(row.id)}>Download</Button>
                                    }
                                    {
                                        row.status === 1 &&<Button variant="text" color="secondary" onClick={()=>handleToGo(row.id,row.status)}>Sign</Button>
                                    }



                                     {/*<Button variant="text">edit</Button>*/}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/*<RhtLine>*/}
            {/*    <Pagination count={10} />*/}
            {/*</RhtLine>*/}
        </Box>

    );
}