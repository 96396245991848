import TextField from '@mui/material/TextField';
import Input from "@mui/material/Input";
import styled from "@emotion/styled";
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import RemoveIcon from '@mui/icons-material/Remove';
import {useEffect, useState} from "react";
import {useContextProvider} from "../../providers/contextProvider";
import {useWeb3React} from "@web3-react/core";
import axios from "axios";
import IconButton from '@mui/material/IconButton';

const Box = styled("div")`
`
const ContentBox = styled("div")`
  padding-top: 30px;
    dl{
      margin-bottom: 20px;
      display: flex;
      dt{
        //width: 150px;
        flex-shrink: 0;
        padding: 10px 20px 0 0;
        min-width: 150px;
        //margin-bottom: 10px;
        //opacity: 0.6;
        font-family: "Poppins-Bold";
      }
      dd{
        //padding-left: 20px;
        flex-grow: 1;
      }
    }
`
const CreatorBox = styled("div")`
  display: flex;
  align-items: center;
  width: 650px;
  box-shadow: 0px 1px 2px rgba(8, 15, 24, 0.1);
  background: #f9f9f9;
  box-sizing: border-box;
  border-radius: 15px;
  height: 60px;
  overflow: hidden;
  margin-right: 20px;
  span{
    display: inline-block;
    background: #F3EFFF;
    padding: 20px 30px;
    width: 100px;
    flex-shrink: 0;
    border-right: 1px solid rgba(0,0,0,0.03);
  }
`

const SignerBox = styled("div")`

  display: flex;
  align-items: center;
  margin-top: 20px;

  .flexLine{
    display: flex;
    align-content: center;
    width: 650px;
    background: #fff;
    box-shadow:  0px 1px 2px rgba(8, 15, 24, 0.1);
    border-radius: 15px;
    overflow: hidden;
  }
  .lft{
    white-space: nowrap;
    display: inline-block;
    display: inline-block;
    background: #F3EFFF;
    padding: 15px 30px;
    width: 100px;
    flex-shrink: 0;
    border-right: 1px solid rgba(0,0,0,0.03);
  }
  .rht{
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
  .innerLft{
    flex-grow:1;
  }
  margin-bottom: 20px;
  &:nth-child(4n+1){
    .lft{
      background: #F3EFFF;
    }
  }
  &:nth-child(4n+2){
    .lft{
      background: #FFF1F0;

    }
  } 
  &:nth-child(4n+3){
    .lft{
      background: #E8FFF1;
    }
  }&:nth-child(4n){
    .lft{
      background: #fff7e4;

    }
  }
`
const BtnGroup = styled("div")`
    display: flex;
  padding: 10px 0 0 180px;
`

const InputBox = styled.div`
  padding: 20px 30px;
  color: #aaa;
`
const InputInner = styled(Input)`
    box-shadow: none!important;
  width: 100%;
  box-sizing: border-box;
  padding-left: 25px;
`

const CreatorFlex = styled("div")`
    display: flex;
  align-content: center;
`

export default function Step1(){
    const {state,dispatch} = useContextProvider(null);
    const {file,addList} = state;
    const { account } = useWeb3React();
    const [fileName,setFileName] = useState('');
    const [signerList,setSignerList] = useState([
        {
            address:""
        }
    ]);


    useEffect(()=>{
        if(!file) return;
        const {name} = file;
        setFileName(name)
    },[file]);

    useEffect(()=>{
        if(!addList)return;

        const arr = addList.filter(item=>item.address!== account);
        setSignerList(arr)
    },[addList])

    const handleAdd = () =>{
        let arr = [...signerList];
        arr.push({
            address:""
        });
        setSignerList(arr);
    }

    const handleInput =(e,index)=>{
        const {value} = e.target;

            let arr = [...signerList];
            arr[index] = {
                address:value
            }
            setSignerList(arr);
    }

    const handleInputName  =(e) =>{
        const {value} = e.target;
        setFileName(value);
    }

    const RemoveItem = (e,index)=>{
        let arr = [...signerList];
       arr.splice(index,1);
        setSignerList(arr);
    }

    const uploadFile = async() =>{

        let data = new FormData();
        data.append('file', file);
        const { url } = window.configInfo;
        const REACT_APP_API_KEY = process.env.REACT_APP_API_KEY;
        const REACT_APP_API_SECRET = process.env.REACT_APP_API_SECRET;

        console.log(REACT_APP_API_KEY,REACT_APP_API_SECRET)

        dispatch({type:"SET_LOADING",payload:true})
        return axios.post(url,
            data,
            {
                headers: {
                    'Content-Type': `multipart/form-data; boundary= ${data._boundary}`,
                    // 'pinata_api_key': REACT_APP_API_KEY,
                    // 'pinata_secret_api_key': REACT_APP_API_SECRET
                    'pinata_api_key': '5ca59c11f928cd698b22',
                    'pinata_secret_api_key': 'b6482523dfbeb03eb1fdce42532601bc436f1c90769a5d2a5ef06daee8b693c4'
                }
            }
        ).then(function (response) {
            dispatch({type:"SET_LOADING",payload:null})
            const {IpfsHash} = response.data;
            console.log(IpfsHash)
            let arr = [
                {
                    address:account
                },
                ...signerList];
            dispatch({type: 'SET_ADD_LIST', payload:arr});
            dispatch({type: 'SET_CURRENT', payload: 2});
            dispatch({type: 'SET_FILE_HASH', payload: IpfsHash});
        }).catch(function (error) {
            console.error(error)
            dispatch({type:"SET_LOADING",payload:null})
        });
    };


    return <Box>
        <div className="stepLine">
            <div className="stepNo">2</div>
            <div className="stepTitle">Complete information</div>
        </div>
        <ContentBox>
            <dl>
                <dt>Protocol Name</dt>
                <dd>
                    <Input  sx={{minWidth: 650}} value={fileName} onChange={(e)=>handleInputName(e)}/>
                </dd>
            </dl>
            <dl>
                <dt>Signers</dt>
                <dd>
                    <CreatorFlex>
                        <CreatorBox>
                            <span>Creator</span>
                            <InputBox>
                                {account}
                            </InputBox>
                        </CreatorBox>
                        {
                            !signerList.length && <IconButton component="label" color="primary" onClick={()=>handleAdd()}  disableRipple={true}><AddIcon /></IconButton>
                        }
                    </CreatorFlex>

                    {
                        signerList.map((item,index)=>(<SignerBox key={index}>
                            <div className="flexLine">
                                <div className="lft">
                                    <span>Signers {index+1}</span>
                                </div>
                                <div className="innerLft">
                                    <InputInner label="signer wallet address" variant="outlined" onChange={(e)=>handleInput(e,index)} value={item.address}/>
                                </div>
                            </div>
                            <div className="rht">

                                {
                                    index === (signerList.length-1) && <IconButton component="label" color="primary" onClick={()=>handleAdd()}  disableRipple={true}><AddIcon /></IconButton>
                                }
                                 <IconButton component="label" color="primary"  disableRipple={true}><RemoveIcon onClick={(e)=>RemoveItem(e,index)} /></IconButton>
                            </div>
                        </SignerBox>))
                    }

                </dd>
            </dl>
        </ContentBox>
        <BtnGroup>
            <Button variant="contained" onClick={()=>uploadFile()} disabled={!addList?.length && !file}>Next</Button>
        </BtnGroup>
    </Box>
}