import { initializeConnector } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { WalletConnect } from "@web3-react/walletconnect";
import { useMemo } from "react";

const onError = (error) => {
    console.debug(`web3-react error: ${error}`);
};

export const [metaMask, hooks] = initializeConnector((actions) => new MetaMask({ actions, onError }));


export const [coinbaseWallet, coinbaseWalletHooks] = initializeConnector(
    (actions) =>
        new CoinbaseWallet({
            actions,
            options: {
                url: "https://eth-mainnet.alchemyapi.io/v2/yourAlchemyId",
                appName: "test",
                appLogoUrl: "",
            },
            onError,
        })
);

export const [walletConnect, walletConnectHooks] = initializeConnector(
    (actions) =>
        new WalletConnect({
            actions,
            options: {
                rpc: "",
                qrcode: true,
            },
            onError,
        })
);

export const Wallet = {
    METAMASK: "METAMASK",
    COINBASE_WALLET: "COINBASE_WALLET",
    WALLET_CONNECT: "WALLET_CONNECT",
    FORTMATIC: "FORTMATIC",
    NETWORK: "NETWORK",
    GNOSIS_SAFE: "GNOSIS_SAFE",
};

export const getConnectorForWallet = (wallet) => {
    switch (wallet) {
        case Wallet.METAMASK:
            return metaMask;
        case Wallet.COINBASE_WALLET:
            return coinbaseWallet;
        case Wallet.WALLET_CONNECT:
            return walletConnect;
        default:
            return;
    }
};

function getHooksForWallet(wallet) {
    switch (wallet) {
        case Wallet.METAMASK:
            return hooks;
        case Wallet.COINBASE_WALLET:
            return coinbaseWalletHooks;
        case Wallet.WALLET_CONNECT:
            return walletConnectHooks;
        default:
            return;
    }
}

function getConnectorListItemForWallet(wallet) {
    return {
        connector: getConnectorForWallet(wallet),
        hooks: getHooksForWallet(wallet),
    };
}

export const SELECTABLE_WALLETS = [Wallet.METAMASK, Wallet.COINBASE_WALLET, Wallet.WALLET_CONNECT];

export const useConnectors = (selectedWallet) => {
    return useMemo(() => {
        const connectors = [];
        if (selectedWallet) {
            connectors.push(getConnectorListItemForWallet(selectedWallet));
        }
        connectors.push(...SELECTABLE_WALLETS.filter((wallet) => wallet !== selectedWallet).map(getConnectorListItemForWallet));
        const web3ReactConnectors = connectors.map(({ connector, hooks }) => [connector, hooks]);
        return web3ReactConnectors;
    }, [selectedWallet]);
};
