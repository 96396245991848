import styled from "@emotion/styled";
import Button from "@mui/material/Button";

const Box = styled("div")`
    padding-bottom: 60px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const PdfBox = styled("div")`
    flex-grow: 1;
  background: #ff0;
`

const BtnGroup = styled("div")`
    display: flex;
  justify-content: flex-end;
  padding-top: 40px;
`


export default function Step3(){
    return <Box>
        <div className="stepLine">
            <div className="stepNo">4</div>
            <div className="stepTitle">Review</div>
        </div>
        <PdfBox></PdfBox>
        <BtnGroup>
            <Button variant="contained">Sign and create</Button>
        </BtnGroup>
    </Box>
}