import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Header from "./components/header";
import ShareIcon from '@mui/icons-material/Share';
import {useWeb3React} from "@web3-react/core";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {PdfSignViewer, SignAPI} from "test-dao-sign";
import {ethers} from "ethers";
import {useContextProvider} from "./providers/contextProvider";

const BoxBg = styled("div")`
    display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const ContentBox = styled("div")`
  padding: 10px 80px 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: #f9f9fc;
`

const PdfBox = styled("div")`
    flex-grow: 1;
`

const BtnGroup = styled("div")`
    display: flex;
  justify-content: flex-end;
  //padding-top: 40px;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 10px;
  box-sizing: border-box;
  width: 20%;
  margin-right: 80px;
  z-index: 999;
  background: #f9f9fc;
`
const TitleBox = styled("div")`
    display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
  .title{
    padding-right: 20px;
  }
`

export default function Sign(){
    const { account,provider } = useWeb3React();
    const {dispatch} = useContextProvider(null);
    const navigate = useNavigate();
    const {id} = useParams();
    const [detail,setDetail] = useState();
    const [list,setList] = useState([]);
    const [dis,setDis] = useState(true);
    const [hashStr,setHashStr] = useState('');

    useEffect(()=>{
        if(!id || !account)return;
        getDetail(id)
    },[id,account])

    const getDetail = async(id) =>{
        let details = await SignAPI.getAgreementDetail(id);
        const {agreementHash} = details;
        setHashStr(agreementHash)
        setDetail(details);
    }

    const handleSubmit = async() =>{
        dispatch({type:"SET_LOADING",payload:true})
        const index = list.findIndex(item=>item.address === account);
        const signArr = list.filter(item=>item.address === account);
        let msg = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(hashStr));
        const _signature = await provider.send("personal_sign", [msg, account]);
        console.log(_signature);
        let rt = await SignAPI.Sign(id,index,_signature,JSON.stringify(signArr[0]),account);
        console.log(rt)
        dispatch({type:"SET_LOADING",payload:null});
        if(rt?.type === "success"){
            navigate("/");
        }

    }

    useEffect(()=>{
        const index = list.findIndex(item=>item.address === account);
        if(index === -1){
            setDis(true)
        }else{
            setDis(false)
        }

    },[list])

    const handleSign = (list) =>{
        setList(list)
    }
    return <BoxBg>
        <Header />
        <ContentBox>
            {/*<TitleBox>*/}
            {/*    <div className="title">{detail?.name}</div>*/}
            {/*    <ShareIcon fontSize="small" color="primary"/>*/}
            {/*</TitleBox>*/}
            <PdfBox>
                <PdfSignViewer handleSign={handleSign} signToDoList={detail} currentSigner={account} type="SIGN" />
            </PdfBox>
            <BtnGroup>
                <Button variant="contained" onClick={()=>handleSubmit()} disabled={dis}>Sign</Button>
            </BtnGroup>
        </ContentBox>

    </BoxBg>
}