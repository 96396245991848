import styled from "@emotion/styled";
import Header from "./components/header";
import Step1 from "./components/create/step1";
import {useEffect, useState} from "react";
import Step2 from "./components/create/step2";
import Step3 from "./components/create/step3";
import Step4 from "./components/create/step4";
import {useContextProvider} from "./providers/contextProvider";

const BoxBg = styled("div")`
    display: flex;
  flex-direction: column;
  min-height: 100vh;
  
`
const ContentBox = styled("div")`
  padding: 40px 80px;
  flex-grow: 1;
  background: #f9f9fc;
`

export default function Create(){
    const {state} = useContextProvider(null);
    const {currentPage} = state;
    const [current,setCurrent] = useState(1);
    // const [currentPage,setCurrentPage] = useState(1);

    useEffect(()=>{
        let page = currentPage?currentPage:1;
        setCurrent(page)
    },[currentPage])

    return <BoxBg>
        <Header />
        {
            current === 1 &&<ContentBox>
                <Step1 />
                <Step2 />
            </ContentBox>
        }
        {
            current === 2 && <ContentBox>
                <Step3 />
            </ContentBox>
        }
        {
            current === 3 && <ContentBox>
                <Step4 />
            </ContentBox>
        }

    </BoxBg>
}