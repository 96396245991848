import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const Box = styled('div')`
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255,255,255,0.1);
  backdrop-filter: blur(5px);
  z-index: 999;
`

export default function Loading(){
    return <Box>
        <CircularProgress />
    </Box>
}