const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, loading: action.payload };
        case 'SET_FILE':
            return { ...state, file: action.payload };
        case 'SET_CURRENT':
            return { ...state, currentPage: action.payload };
        case 'SET_ADD_LIST':
            return { ...state, addList: action.payload };
        case 'SET_FILE_HASH':
            return { ...state, fileHash: action.payload };
        default:
            throw new Error(`Unknown type: ${action.type}`);
    }
};
export default reducer
