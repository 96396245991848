import Routerlink from "./utils/router";
import Web3Provider from "./providers/web3Provider";
import { Web3ContextProvider } from "./providers/contextProvider";
import GlobalStyle from "./utils/GlobalStyle";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    typography: {
        fontFamily: "Poppins-Regular",
        button:{
            textTransform:"none",
            fontFamily:"Poppins-Bold"
        }
    },
    shape: {
        borderRadius:12,
    },
    palette: {
        primary: {
            main: '#343EDF',
        },
        secondary:{
            main: "#23D288"
        }
    },
    components:{
        MuiTab:{
            styleOverrides:{
                root:{
                    color:"#000",
                    marginRight:"20px",
                    fontFamily:"Poppins-Regular",
                    "&.Mui-selected":{
                        fontFamily:"Poppins-Bold",
                    }
                },

            }
        },
        MuiSelect:{
            styleOverrides:{
                root:{
                    border:0,
                    ":hover":{
                        "&::before":{
                            borderBottom:"0!important"
                        },
                        "&::after":{
                            borderBottom:"0!important"
                        },
                    }
                },
            }
        },
        MuiInput:{
            styleOverrides:{
                root:{
                    ":before":{
                        border:0
                    },
                    ":after":{
                        border:0
                    },
                    padding: "10px 20px",
                    boxSizing:"border-box",
                    borderRadius:15,
                    minHeight:"50px",
                    background:"#fff",
                    boxShadow:" 0px 1px 2px rgba(8, 15, 24, 0.1)",
                    ":hover":{
                        "&::before":{
                            borderBottom:"0!important"
                        },
                        "&::after":{
                            borderBottom:"0!important"
                        },
                    }
                }
            }
        },
        MuiButton:{
            styleOverrides:{
                root:{
                    padding:"13px 62px",
                    borderRadius: 24,
                },
                outlined:{
                    border:0,
                    background:"#F8F9FF",
                    filter:"drop-shadow(0px 1px 2px rgba(8, 15, 24, 0.1))",
                    ":hover":{
                        border:0
                    }
                }
            }
        }
    }
});
function App() {
  return (
    <div >
        <ThemeProvider theme={theme}>
        <Web3ContextProvider>
            <Web3Provider>
                <Routerlink />
            </Web3Provider>

        </Web3ContextProvider>
        </ThemeProvider>
        <GlobalStyle />

    </div>
  );
}


export default App;
