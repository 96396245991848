import{useContext, useReducer,createContext} from 'react';
import INIT_STATE from './initState';
import reducer from './reducer';

const initState = {...INIT_STATE};

const Web3Context = createContext();
const Web3ContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initState);
    console.log("=====state=====",state);

    return <Web3Context.Provider value={{state,dispatch}}>
        {props.children}
    </Web3Context.Provider>;
};

const useContextProvider = () => ({...useContext(Web3Context)});
export {Web3ContextProvider, useContextProvider};