import { useEffect } from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { getConnectorForWallet, useConnectors } from "./connector";

const connect = async (connector) => {
    try {
        await connector.activate();
    } catch (error) {
        console.debug(`web3-react eager connection error: ${error}`);
    }
};

export default function Web3Provider(props) {
    const selectedWallet = localStorage.getItem("wallet");
    const connectors = useConnectors(selectedWallet);

    useEffect(() => {
        if (selectedWallet) {
            connect(getConnectorForWallet(selectedWallet));
        }
    }, []);

    return <Web3ReactProvider connectors={[...connectors]}>{props.children}</Web3ReactProvider>;
}
