import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import ConnectBtn from "./wallet/ConnectBtn";
import { useWeb3React } from "@web3-react/core";
import {useContextProvider} from "../providers/contextProvider";
import Loading from "./loading";
import PublicJs from "../utils/public";
import ChainJson from "../utils/chain.json";
import LogoImg from  "../images/logo.png";

const Box = styled("div")`
    flex-shrink: 0;
`


const Operater = styled("div")``;

const HeaderContainer = styled("header")`
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 80px;
`;

const LogoBox = styled("div")`
  img{
    height: 50px;
  }
`;

const BtnRht = styled("div")`
    display: flex;
  align-items: center;
  justify-content: flex-end;
  .address{
    color: #343EDF;
  }
  .rht{
    margin-right: 20px;
  }
`


export default function Header() {
    const navigate = useNavigate();
    const { account, provider } = useWeb3React();

    const {dispatch,state} = useContextProvider();
    const {loading} = state;


    const [chainName ,setChainName] = useState('');
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        if(!provider||!account  )return;
        getChain()
    },[provider,account])

    const getChain =  async() =>{
        const { chainId } = await provider.getNetwork();
        const ChainArr = ChainJson.filter(item=>item.chainId === chainId);
        setChainName(ChainArr[0]?.chain);
    }

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleToGo = (url) =>{
        navigate(url);
    }
    const LogOut = () =>{
        localStorage.removeItem("wallet");
        window.location.reload();
    }

  return (
      <Box>
          {
              loading && <Loading />
          }
          <ConnectBtn  open={open} handleClose={handleClose} />
          <HeaderContainer>
              <LogoBox onClick={()=>handleToGo('/')}>
                  <img src={LogoImg} alt=""/>
              </LogoBox>
              <div>
                  {
                      !account && <Operater>
                          <Button variant="outlined" onClick={()=>handleClickOpen()}>Connect Wallet</Button>
                      </Operater>
                  }
                  {
                      account &&  <BtnRht>
                          <Button variant="outlined" className="rht">{chainName}</Button>
                          <span className="address">{PublicJs.AddresstoShow(account)}</span>
                          <Button variant="text"  color="secondary" onClick={()=>LogOut()}>Log out</Button>
                      </BtnRht>
                  }
              </div>
          </HeaderContainer>
      </Box>

  );
}


